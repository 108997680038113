<template>
  <section
    id="about-techAcademy"
    class="co-bg-striped"
  >
    <div class="co-container-lg px-4 py-20">
      <div>
        <h2 class="co-heading-2-caption">
          テックアカデミーとは？
        </h2>
        <h3 class="co-display-10 mb-12 text-left leading-base text-co-gray-500 md:text-center">
          <span class="inline md:block">現役エンジニアから学べるオンラインに特化したプログラミングスクール。</span>
          <span class="inline md:block">講師は全員、通過率10％の選考に合格した現役エンジニア。</span>
          <span class="inline md:block">確かなスキルをもとに受講生をマンツーマンサポートします。</span>
        </h3>
      </div>
      <p class="co-display-10 mb-[3.25rem] text-center">
        900社、10万人を超える教育実績
      </p>
      <div class="co-container-sm grid grid-cols-3 gap-x-7 gap-y-5 text-center md:grid-cols-5">
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_17.png"
          alt="KIRIN"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_01.png"
          alt="ぐるなび"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_18.png"
          alt="三菱UFJ"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_konicaminolta.png"
          alt="KONICA MINOLTA"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_03.png"
          alt="コロプラ"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_02.png"
          alt="SAKURA internet"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_04.png"
          alt="フジテレビ"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_05.png"
          alt="価格.com"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_06.png"
          alt="aucfan"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_08.png"
          alt="食べログ"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_09.png"
          alt="pixiv"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_10.png"
          alt="AOKI"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_11.png"
          alt="neocareer"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_12.png"
          alt="LIVESENSE"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_13.png"
          alt="istyle"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_14.png"
          alt="AXAS"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_19.png"
          alt="ISP"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_16.png"
          alt="Link and Motivation Group"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_20.png"
          alt="D2C dot"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_seibubus.png"
          alt="西武バス"
          loading="lazy"
        >
        <img
          class="te-about-logo mx-auto w-full"
          src="~/assets/images/Te/TeCommon/AboutTechAcademy/logolist_recruit.png"
          alt="リクルート"
          loading="lazy"
        >
      </div>
    </div>
  </section>
</template>

<style scoped>
.te-about-logo {
  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 9.5625rem;
  }
}
</style>
