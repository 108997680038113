<template>
  <section
    id="works"
    class="co-bg-striped px-4 py-12 sm:py-20"
  >
    <div class="co-container-lg px-4">
      <h2 class="co-heading-2">
        受講すると<span class="text-co-primary-400">ここまで作れる</span>
        <span class="inline md:inline">ようになります</span>
      </h2>
    </div>
    <div class="co-container-lg grid grid-cols-2 gap-x-4 md:grid-cols-4 md:gap-x-8">
      <!--  モーダルボタン -->
      <div>
        <div class="te-border-first-sidejob mb-2 border-b-4 md:mb-4">
          <span class="te-course-name font-bold leading-none text-co-gray-600">
            はじめての副業コース
          </span>
        </div>
        <button
          class="co-btn-text hover:text-co-info-400 border-0 bg-transparent p-0 align-baseline"
          onclick="modal_first_sidejob.showModal()"
        >
          <img
            class="te-graph-sparetime mb-5 w-full hover:opacity-80 md:mb-8"
            src="~/assets/images/Te/TeTop/Work/works-image-1.png"
            loading="lazy"
            width="369"
          >
        </button>
      </div>
      <!--  モーダル -->
      <dialog
        id="modal_first_sidejob"
        class="modal"
      >
        <div class="modal-box max-w-[800px]">
          <!-- 右上の閉じるボタン -->
          <form method="dialog">
            <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
              ✕
            </button>
          </form>
          <!-- モーダルのコンテンツ -->
          <div class="te-modal-body py-6">
            <h5 class="co-display-10 font-bold">
              はじめての副業コース
            </h5>
            <div class="text-center">
              <p class="co-display-8 mb-8 pt-4 font-bold text-co-gray-600">
                受講中の課題で作成する<br>架空のコーポレートサイト
              </p>
              <img
                class="mx-auto mb-9 border border-co-gray-300"
                src="~/assets/images/Te/TeTop/Work/works-sample-1.jpg"
                loading="lazy"
                width="480"
              >
              <a
                class="co-btn-primary mb-5"
                :href="$host() + '/course/first-sidejob'"
              >コースの詳細を見る</a>
            </div>
          </div>
        </div>
        <form
          method="dialog"
          class="modal-backdrop"
        >
          <button>close</button>
        </form>
      </dialog>
      <!--  モーダルボタン -->
      <div>
        <div class="te-border-webdesign mb-2 border-b-4 md:mb-4">
          <span class="te-course-name font-bold leading-none text-co-gray-600">
            Webデザインコース
          </span>
        </div>
        <button
          class="co-btn-text hover:text-co-info-400 border-0 bg-transparent p-0 align-baseline"
          onclick="modal_webdesign.showModal()"
        >
          <img
            class="te-graph-sparetime mb-5 w-full hover:opacity-80 md:mb-8"
            src="~/assets/images/Te/TeTop/Work/works-image-2.png"
            loading="lazy"
            width="369"
          >
        </button>
      </div>
      <!--  モーダル -->
      <dialog
        id="modal_webdesign"
        class="modal"
      >
        <div class="modal-box max-w-[800px]">
          <!-- 右上の閉じるボタン -->
          <form method="dialog">
            <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
              ✕
            </button>
          </form>
          <!-- モーダルのコンテンツ -->
          <div class="te-modal-body py-6">
            <h5 class="co-display-10 font-bold">
              Webデザインコース
            </h5>
            <div class="text-center">
              <p class="co-display-8 mb-8 pt-4 font-bold text-co-gray-600">
                受講生 梅澤 早紀さんの<br>オリジナル作品
              </p>
              <img
                class="mx-auto mb-9 border border-co-gray-300"
                src="~/assets/images/Te/TeTop/Work/works-sample-2.jpg"
                loading="lazy"
                width="480"
              >
              <a
                class="co-btn-primary mb-5"
                :href="$host() + '/course/webdesign'"
              >コースの詳細を見る</a>
            </div>
          </div>
        </div>
        <form
          method="dialog"
          class="modal-backdrop"
        >
          <button>close</button>
        </form>
      </dialog>
      <!--  モーダルボタン -->
      <div>
        <div class="te-border-frontend mb-2 border-b-4 md:mb-4">
          <span class="te-course-name font-bold leading-none text-co-gray-600">
            フロントエンドコース
          </span>
        </div>
        <button
          class="co-btn-text hover:text-co-info-400 border-0 bg-transparent p-0 align-baseline"
          onclick="modal_frontend.showModal()"
        >
          <img
            class="te-graph-sparetime mb-5 w-full hover:opacity-80 md:mb-0"
            src="~/assets/images/Te/TeTop/Work/works-image-4.png"
            loading="lazy"
            width="369"
          >
        </button>
      </div>
      <!--  モーダル -->
      <dialog
        id="modal_frontend"
        class="modal"
      >
        <div class="modal-box max-w-[800px]">
          <!-- 右上の閉じるボタン -->
          <form method="dialog">
            <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
              ✕
            </button>
          </form>
          <!-- モーダルのコンテンツ -->
          <div class="te-modal-body py-6">
            <h5 class="co-display-10 font-bold">
              フロントエンドコース
            </h5>
            <div class="text-center">
              <p class="co-display-8 mb-8 pt-4 font-bold text-co-gray-600">
                受講生 内野 佑里さんの<br>オリジナル作品
              </p>
              <img
                class="mx-auto mb-9 border border-co-gray-300"
                src="~/assets/images/Te/TeTop/Work/works-sample-4.jpg"
                loading="lazy"
                width="480"
              >
              <a
                class="co-btn-primary mb-5"
                :href="$host() + '/course/frontend'"
              >コースの詳細を見る</a>
            </div>
          </div>
        </div>
        <form
          method="dialog"
          class="modal-backdrop"
        >
          <button>close</button>
        </form>
      </dialog>
      <!--  モーダルボタン -->
      <div>
        <div class="te-border-unity mb-2 border-b-4 md:mb-4">
          <span class="te-course-name font-bold leading-none text-co-gray-600">
            Unityコース
          </span>
        </div>
        <button
          class="co-btn-text hover:text-co-info-400 border-0 bg-transparent p-0 align-baseline"
          onclick="modal_unity.showModal()"
        >
          <img
            class="te-graph-sparetime w-full hover:opacity-80"
            src="~/assets/images/Te/TeTop/Work/works-image-5.png"
            loading="lazy"
            width="369"
          >
        </button>
      </div>
      <!--  モーダル -->
      <dialog
        id="modal_unity"
        class="modal"
      >
        <div class="modal-box max-w-[800px]">
          <!-- 右上の閉じるボタン -->
          <form method="dialog">
            <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
              ✕
            </button>
          </form>
          <!-- モーダルのコンテンツ -->
          <div class="te-modal-body py-6">
            <h5 class="co-display-10 font-bold">
              Unityコース
            </h5>
            <div class="text-center">
              <p class="co-display-8 mb-8 pt-4 font-bold text-co-gray-600">
                受講生 野上 真司さんの<br>オリジナル作品
              </p>
              <img
                class="mx-auto mb-9 border border-co-gray-300"
                src="~/assets/images/Te/TeTop/Work/works-sample-5.jpg"
                loading="lazy"
                width="480"
              >
              <a
                class="co-btn-primary mb-5"
                :href="$host() + '/course/unity'"
              >コースの詳細を見る</a>
            </div>
          </div>
        </div>
        <form
          method="dialog"
          class="modal-backdrop"
        >
          <button>close</button>
        </form>
      </dialog>
    </div>
  </section>
</template>

<style scoped>
.te-course-name {
  font-size: 0.75rem;
  @media (min-width: 768px) {
    font-size: 0.875rem;
  }
  @media (min-width: 992px) {
    font-size: 1.25rem;
  }
}

.te-border-first-sidejob {
  border-image: linear-gradient(to right, #7ecc31 0%, #7ecc3100 100%);
  border-image-slice: 1;
}

.te-border-webdesign {
  border-image: linear-gradient(to right, #cdbf49 0%, #cdbf4900 100%);
  border-image-slice: 1;
}

.te-border-frontend {
  border-image: linear-gradient(to right, #8c7acb 0%, #8c7acb00 100%);
  border-image-slice: 1;
}

.te-border-unity {
  border-image: linear-gradient(to right, #5c5c5c 0%, #5c5c5c00 100%);
  border-image-slice: 1;
}
</style>
