<template>
  <section class="co-bg-striped px-4 py-5">
    <div class="co-container-xs text-center">
      <a
        class="mx-auto block hover:opacity-80 md:hidden"
        :href="$host() + '/course/occupation'"
        style="max-width: 400px;"
      >
        <img
          class="mx-auto w-full"
          src="~/assets/images/Te/TeTop/BootcampRecommend/occupation-banner-sp-test.webp"
          alt="コース選びに役立つ解説！これを読めばWeb制作の仕事がまるわかり"
          width="400"
          loading="lazy"
        >
      </a>
      <a
        class="mx-auto hidden hover:opacity-80 md:block"
        :href="$host() + '/course/occupation'"
      >
        <img
          class="mx-auto"
          src="~/assets/images/Te/TeTop/BootcampRecommend/occupation-banner-pc-test.webp"
          alt="コース選びに役立つ解説！これを読めばWeb制作の仕事がまるわかり"
          width="740"
          loading="lazy"
        >
      </a>
    </div>
  </section>
</template>
