<template>
  <div
    id="cover"
    class="bg-[#cee2e9]"
  >
    <div class="te-cover-bg relative pt-[4.25rem] md:pt-10">
      <div
        class="co-container-sm relative z-[1] flex flex-col items-baseline"
      >
        <!-- コピー -->
        <div class="te-cover-title-bg mb-[3.25rem] inline-block py-[.75rem] pl-5 md:mb-8 md:py-5 md:pl-4 lg:pr-[3.25rem]">
          <span class="co-display-13 mb-1 inline-block font-bold leading-normal">
            ライフスタイルに合わせて
          </span>
          <h1 class="te-main-text mb-0 font-bold leading-normal text-co-primary-500">
            必要なスキルを最短距離で！
            <br>
            スクールでしか得られない
            <br>
            確実に副業/転職に繋がる学びを。
          </h1>
        </div>

        <!-- ボタン -->
        <client-only>
          <!-- 無料で相談してみる。.bot_open このクラスはBOTCHAN用として追加 -->
          <TeCommonButtonLabel
            v-if="!paid && !appliedHtmlcssTrial"
            class="te-button bot_open mx-auto block !rounded !py-5 md:mx-0 md:ml-5 lg:inline-block"
            :link="'/' + 'counseling/htmlcss-schedule'"
          >
            <template #buttonLabel>
              <div class="flex items-center">
                <span class="font-bold text-co-gray-600">今なら</span>
                <span class="te-text-counseling font-bold">空席あり</span>

                <FontAwesomeIcon
                  :icon="faCircleO"
                  class="fa-fw te-icon-dobble-circle te-cover-icon ml-1"
                />
              </div>
            </template>
            <template #buttonText>
              <div class="flex items-center justify-center">
                <div class="flex items-center">
                  <img
                    src="~/assets/images/Te/TeTop/Cover/Consultation/icon-button.svg"
                    width="33"
                    height="29"
                  >
                  <span class="co-display-10 px-2 font-bold">無料で相談してみる</span>
                </div>
                <FontAwesomeIcon
                  :icon="faChevronRight"
                  class="fa-fw"
                />
              </div>
            </template>
          </TeCommonButtonLabel>
          <div
            v-if="!paid && !appliedHtmlcssTrial"
            class="te-cover-annotation mx-auto mt-3 flex items-center justify-center text-center md:mx-0 md:ml-5"
          >
            <span
              class="co-display-14 co-text-gray-2"
              style="text-shadow: 0 0 0.2rem #fff, 0 0 0.4rem #fff"
            >
              ※ 無理な勧誘は一切ありません
            </span>
          </div>

          <!-- コース紹介を見る -->
          <div
            v-if="paid || appliedHtmlcssTrial"
            class="pl-5"
          >
            <a
              class="co-btn-primary-dark"
              :href="$host() + '/course'"
            >
              コース紹介を見る
              <FontAwesomeIcon
                :icon="faAngleRight"
              />
            </a>
          </div>
        </client-only>
      </div>

      <!-- 背景白の枠 -->
      <div class="te-cover-title-bg-large absolute" />
    </div>
  </div>
</template>

<script lang="ts">
import { useUser } from '~/store/user'

import TeCommonButtonLabel from '~/components/Te/TeCommon/Button/TeCommonButtonLabel/TeCommonButtonLabel.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronRight, faAngleRight } from '@awesome.me/kit-65d95819ce/icons/classic/solid'
import { faCircleO } from '@awesome.me/kit-65d95819ce/icons/classic/regular'

const options = {
  async setup() {
    const { appliedHtmlcssTrial, paid } = storeToRefs(useUser())

    return {
      appliedHtmlcssTrial,
      paid,
      faChevronRight,
      faCircleO,
      faAngleRight,
    }
  },
  components: {
    TeCommonButtonLabel,
    FontAwesomeIcon,
  },
}

export default defineNuxtComponent(options)
</script>

<style scoped>
.te-cover-bg {
  height: 25rem;
  background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/cover-man-sp.jpg');
  background-position: right bottom;
  background-size: cover;
  @media (min-width: 576px) {
    background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/cover-man-tablet.jpg');
    background-position: center;
  }
  @media (min-width: 992px) {
    background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/cover-man-pc.jpg');
    background-repeat: no-repeat;
    background-position: calc(50% - 3.75rem);
  }
  @media (min-width: 1200px) {
    max-width: 120rem;
    margin: auto;
    background-position: center;
  }
}

.te-cover-title-bg {
  @media (max-width: 899px) {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.te-cover-title-bg-large {
  height: 11.625rem;
  @media (min-width: 900px) {
    top: 2.5rem;
    left: 0;
    z-index: 0;
    width: 50%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  @media (min-width: 992px) {
    height: 12.375rem;
  }
}

.te-main-text {
  font-size: 1rem;
  @media (min-width: 768px) {
    font-size: 1.75rem;
  }
}

.te-button {
  width: 19.437rem;
}

.te-cover-annotation {
  width: 19.437rem;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.te-text-counseling {
  color: #13cd8a;
}

.te-cover-icon {
  margin-top: 0.125rem;
  color: #13cd8a;
}
</style>
