<template>
  <section class="co-bg-striped relative py-5">
    <h2 class="co-display-12 mb-3 w-full text-center font-bold tracking-[.15em] text-co-gray-600">
      ＼ 自分に合うコースがわからない方も ／
    </h2>

    <div class="co-container-xs te-counseling-banner-content mb-3 flex flex-col items-center justify-center px-4 md:flex-row">
      <img
        class="te-counseling-illust"
        src="~/assets/images/Te/TeCommon/Banner/Links/counseling-illust.svg"
        alt="無料相談"
        loading="lazy"
      >
      <p class="mx-0 mb-0 md:ml-6 md:mr-5">
        <span>受講について疑問・質問がある方、</span>
        <span class="font-bold text-co-danger-500">まずは話だけ聞いてみたい方</span>
        <span>もぜひご利用ください。</span>
      </p>
    </div>
    <div class="text-center">
      <!-- .bot_open このクラスはBOTCHAN用として追加 -->
      <a
        class="co-btn-counseling bot_open gap-2 rounded-full"
        :href="$host() + '/counseling/htmlcss-schedule'"
      >
        無料で相談してみる
        <i class="fa fa-angle-right ml-2" />
      </a>
    </div>
  </section>
</template>

<style scoped>
.te-counseling-banner-content {
  gap: 0.75rem;
  @media (min-width: 768px) {
    gap: 0;
  }
  .te-counseling-illust {
    max-width: 15rem;
    max-height: 5.5625rem;
    @media (min-width: 992px) {
      max-width: 18.125rem;
      max-height: 6.75rem;
    }
  }
}
</style>
